import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #333;
  background-color: #f0f0f0;
`;

const Button = styled.button`
  margin: 10px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

const AudioControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const Attribution = styled.a`
  margin-top: 10px;
  font-size: 14px;
  color: #666;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const VolumeControl = styled.input`
  width: 200px;
  margin-top: 10px;
`;

function App() {
  const [currentTrack, setCurrentTrack] = useState("night-in-kyoto");
  const [isPlaying, setIsPlaying] = useState(false);
  const [error, setError] = useState(null);
  const [volume, setVolume] = useState(0.5);
  const audioRef = useRef(null);

  const tracks = {
    "night-in-kyoto": {
      src: "/audio/night-in-kyoto-avbe-main-version-21302-01-57.mp3",
      attribution: "https://uppbeat.io/t/avbe/night-in-kyoto",
    },
    "golden-summer-lenny-b": {
      src: "/audio/golden-summer-lenny-b-fortune-cookie-main-version-31904-02-13.mp3",
      attribution: "https://uppbeat.io/t/fortune-cookie/golden-summer-lenny-b",
    },
    "the-cleaner": {
      src: "/audio/the-cleaner-night-drift-main-version-20732-02-01.mp3",
      attribution: "https://uppbeat.io/t/night-drift/the-cleaner",
    },
  };

  useEffect(() => {
    const pollSession = async () => {
      try {
        const response = await fetch(
          "https://braid-google-meet-bot-prototype-music-controller.noshado.ws/session"
        );
        const data = await response.json();
        if (data.playing !== isPlaying) {
          setIsPlaying(data.playing);
          if (data.playing) {
            audioRef.current.play().catch(handlePlaybackError);
          } else {
            audioRef.current.pause();
          }
        }
      } catch (error) {
        console.error("Error polling session:", error);
      }
    };

    const intervalId = setInterval(pollSession, 1000); // Poll every second

    return () => clearInterval(intervalId); // Clean up on component unmount
  }, [isPlaying]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
      audioRef.current.volume = volume;
      if (isPlaying) {
        audioRef.current.play().catch(handlePlaybackError);
      }
    }
  }, [currentTrack, isPlaying, volume]);

  const handlePlaybackError = (error) => {
    console.error("Playback error:", error);
    setError("An error occurred while playing the audio.");
    setIsPlaying(false);
  };

  const handleTrackChange = (track) => {
    setCurrentTrack(track);
  };

  const handlePlay = async () => {
    try {
      const response = await fetch(
        "https://braid-google-meet-bot-prototype-music-controller.noshado.ws/play"
      );
      if (response.ok) {
        setIsPlaying(true);
        audioRef.current.play().catch(handlePlaybackError);
      } else {
        throw new Error("Failed to start playback");
      }
    } catch (error) {
      console.error("Error starting playback:", error);
      setError("Failed to start playback.");
    }
  };

  const handlePause = async () => {
    try {
      const response = await fetch(
        "https://braid-google-meet-bot-prototype-music-controller.noshado.ws/pause"
      );
      if (response.ok) {
        setIsPlaying(false);
        audioRef.current.pause();
      } else {
        throw new Error("Failed to pause playback");
      }
    } catch (error) {
      console.error("Error pausing playback:", error);
      setError("Failed to pause playback.");
    }
  };

  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
  };

  return (
    <Page>
      <h1>Audio Player</h1>
      <audio ref={audioRef}>
        <source src={tracks[currentTrack].src} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <div>
        <Button onClick={() => handleTrackChange("night-in-kyoto")}>
          Night in Kyoto
        </Button>
        <Button onClick={() => handleTrackChange("golden-summer-lenny-b")}>
          Golden Summer (Lenny B)
        </Button>
        <Button onClick={() => handleTrackChange("the-cleaner")}>
          The Cleaner
        </Button>
      </div>
      <AudioControls>
        <Button onClick={isPlaying ? handlePause : handlePlay}>
          {isPlaying ? "Pause" : "Play"}
        </Button>
        <p>Now playing: {currentTrack}</p>
        <VolumeControl
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
        />
        <Attribution
          href={tracks[currentTrack].attribution}
          target="_blank"
          rel="noopener noreferrer"
        >
          Music from Uppbeat
        </Attribution>
      </AudioControls>
    </Page>
  );
}

export default App;
